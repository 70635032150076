import { IconCheckCircleOutline, theme, IconProps } from '@coles/rocket'

export interface SuccessTickCircleRocketProps extends IconProps {
  size?: number
  id?: string
}

export function SuccessTickCircleRocket(props: SuccessTickCircleRocketProps) {
  const { size, ...rest } = props

  const height = size ? `${size}px` : '74px'
  const width = size ? `${size}px` : '74px'

  return (
    <IconCheckCircleOutline
      width={width}
      height={height}
      data-testid='success-tick-circle-rocket'
      color={theme.colorFeedback.success.primary.default}
      {...rest}
    />
  )
}
