import { IconUserCircleOutline, IconWrapper, theme } from '@coles/rocket'

export interface UserCircleOutlineRocketProps {
  size?: number
}

export function UserCircleOutlineRocket(props: UserCircleOutlineRocketProps) {
  return (
    <IconWrapper
      indicator={false}
      icon={
        <IconUserCircleOutline
          color={theme.colorForeground.secondary}
          height={props.size ? `${props.size}` : '20'}
          width={props.size ? `${props.size}` : '20'}
        />
      }
    />
  )
}
