import { Button, Modal, ModalProps } from '@coles/rocket'

export interface GenericModalRocketProps extends Omit<ModalProps, 'title'> {
  closeHandler: () => void
  showFooterButton?: boolean
  FooterButton?: React.FunctionComponent<{ closeHandler?: () => void }>
  footerButtonText?: string
  titleText?: string
}

export function GenericModalRocket({
  showFooterButton,
  FooterButton,
  footerButtonText,
  titleText,
  ...props
}: GenericModalRocketProps) {
  return (
    <Modal
      title={titleText}
      footer={
        showFooterButton ? (
          FooterButton ? (
            <FooterButton closeHandler={props.closeHandler} />
          ) : (
            <>
              <Button
                label={footerButtonText}
                onClick={() => {
                  props.closeHandler()
                }}
                size='medium'
                variant='primary'
              />
            </>
          )
        ) : undefined
      }
      {...props}
    />
  )
}
