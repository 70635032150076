import { IconMapPinOutline, IconWrapper } from '@coles/rocket'

export interface MapPinOutlineIconRocket {
  size?: number
}

export function MapPinOutlineIconRocket(props: MapPinOutlineIconRocket) {
  return (
    <IconWrapper
      indicator={false}
      icon={
        <IconMapPinOutline
          height={props.size ? `${props.size}` : '20'}
          width={props.size ? `${props.size}` : '20'}
        />
      }
    />
  )
}
