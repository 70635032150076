import { LinkButton, LinkProps } from '@coles/rocket'

export interface LinkButtonRocketProps
  extends Omit<LinkProps, 'variant' | 'href'> {
  variant?: LinkProps['variant']
  'data-testid'?: string
}

export function LinkButtonRocket(props: LinkButtonRocketProps) {
  return (
    <LinkButton variant={props.variant || 'primary'} {...props}></LinkButton>
  )
}
