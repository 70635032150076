import { Switch, SwitchProps } from '@coles/rocket'

export interface ThemedSwitchRocketProps
  extends Omit<SwitchProps, 'variant' | 'size'> {
  size?: SwitchProps['size']
  color?: SwitchProps['color']
}

export const ThemedSwitchRocket = (props: ThemedSwitchRocketProps) => {
  const { size, color, ...rest } = props

  return (
    <>
      <Switch
        {...rest}
        size={size || 'medium'}
        color={color || 'primary'}
      ></Switch>
    </>
  )
}
