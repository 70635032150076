import styled from 'styled-components'
import { BodyTextRocket } from '../../text/BodyTextRocket'
import {
  GenericModalRocket,
  GenericModalRocketProps
} from './GenericModalRocket'

const ContentContainer = styled.div`
  padding: 0;
`

const MessageParagraph = styled(BodyTextRocket)`
  padding-bottom: 8px;
`

export function DeleteMobileModalRocket(props: GenericModalRocketProps) {
  return (
    <GenericModalRocket {...props}>
      <ContentContainer>
        <MessageParagraph>
          This will mean you won't receive timely notifiations on your account.
          You also won't be able to use any of your additional account security
          features.
        </MessageParagraph>
      </ContentContainer>
    </GenericModalRocket>
  )
}
