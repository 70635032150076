import { Button, Modal, ModalProps } from '@coles/rocket'

export interface DialogModalRocketProps
  extends Omit<ModalProps, 'title' | 'footer'> {
  confirmButtonText?: string
  cancelButtonText?: string
  titleText?: string

  confirmHandler: () => void
  cancelHandler: () => void
}

export function DialogModalRocket({
  confirmButtonText,
  cancelButtonText,

  titleText,

  confirmHandler,
  cancelHandler,
  ...props
}: DialogModalRocketProps) {
  const okButton = (
    <Button
      label={confirmButtonText ?? 'Confirm'}
      onClick={() => {
        confirmHandler()
      }}
      size='medium'
      variant='primary'
    />
  )

  const cancelButton = (
    <Button
      label={cancelButtonText ?? 'Cancel'}
      onClick={() => {
        cancelHandler()
      }}
      size='medium'
      variant='textSecondary'
      noLinkHover
    />
  )

  const footer = (
    <>
      {cancelButton}
      {okButton}
    </>
  )

  return (
    <Modal
      closeHandler={cancelHandler}
      title={titleText}
      footer={footer}
      {...props}
    ></Modal>
  )
}
