import { HTMLAttributeAnchorTarget } from 'react'
import { Button, ButtonProps, Link, LinkProps } from '@coles/rocket'
import styled from 'styled-components'

export interface LinkRocketProps extends Omit<LinkProps, 'variant' | 'href'> {
  variant?: 'primary' | 'emphasis' | 'light' | 'inline'
  'data-testid'?: string
  href?: string
  target?: HTMLAttributeAnchorTarget
}

const StyledLink = styled(Link)`
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

export function LinkRocket({ variant, ...props }: LinkRocketProps) {
  const { target, ...rest } = props

  return variant === 'emphasis' ? (
    <Button
      {...props}
      size={
        props.size && ['small', 'medium', 'large'].includes(props.size)
          ? (props.size as ButtonProps['size'])
          : 'medium'
      }
      variant='textSecondary'
      noLinkHover
      useAnchor
      as='a'
    />
  ) : (
    <StyledLink
      variant={variant || 'light'}
      href={props.href || undefined}
      {...rest}
      openInNewTab={target === '_blank'}
    />
  )
}
