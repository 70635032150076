import { IconFlybuysOutline, IconWrapper, theme } from '@coles/rocket'

export interface FlybuysOutlineRocketProps {
  size?: number
}

export function FlybuysOutlineRocket(props: FlybuysOutlineRocketProps) {
  return (
    <IconWrapper
      indicator={false}
      icon={
        <IconFlybuysOutline
          color={theme.colorForeground.secondary}
          height={props.size ? `${props.size}` : '20'}
          width={props.size ? `${props.size}` : '20'}
        />
      }
    />
  )
}
