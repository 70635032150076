import { CheckBox, CheckBoxProps } from '@coles/rocket'

export interface CheckBoxRocketProps
  extends Omit<CheckBoxProps, 'variant' | 'size'> {
  size?: CheckBoxProps['size']
}

export const CheckBoxRocket = (props: CheckBoxRocketProps) => {
  const { size, ...rest } = props

  return (
    <>
      <CheckBox {...rest} size={size || 'medium'} />
    </>
  )
}
