import { Message, MessageProps } from '@coles/rocket'
import { ReactNode } from 'react'

export interface ThemedMessageRocketProps
  extends Omit<MessageProps, 'message' | 'name' | 'isFullWidth' | 'hasIcon'> {
  isHidden?: boolean
  isFullWidth?: boolean
  text: ReactNode
  title?: string
  hasIcon?: boolean
  hasCloseButton?: boolean
}

export function ThemedMessageRocket(props: ThemedMessageRocketProps) {
  const {
    isHidden,
    title,
    text,
    hasIcon,
    isFullWidth,
    hasCloseButton,
    ...rest
  } = props
  return !isHidden ? (
    <Message
      data-testid='themed-message-rocket'
      hasIcon={hasIcon ?? true}
      message={text}
      name={title}
      isFullWidth={isFullWidth ?? true}
      role='alert'
      selfClosing={hasCloseButton ?? true}
      {...rest}
    />
  ) : null
}
