import { Pagination, PaginationProps } from '@coles/rocket'

export interface PaginationRocketProps
  extends Pick<PaginationProps, 'defaultPage' | 'totalPages' | 'onChange'> {
  currentPage?: PaginationProps['page']
}

export function PaginationRocket(props: PaginationRocketProps) {
  return (
    <Pagination
      ariaLabel='Pagination navigation'
      defaultPage={props.defaultPage ?? 1}
      onChange={props.onChange}
      page={props.currentPage ?? 1}
      totalPages={props.totalPages}
    />
  )
}
